import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'GtrModuleActivator'
})
export default class GtrModuleActivator extends Vue {
  @Prop({ required: true })
  identifier?: string;

  @Prop({ required: true })
  name?: string;

  @Prop()
  icon?: string;

  @Prop()
  activatable?: boolean;

  @Prop()
  active?: boolean;

  @Prop()
  img?: string;

  @Prop()
  loading?: boolean;

  @Prop({ required: true })
  miniVariant!: boolean

  private handleActivate (event: MouseEvent): void {
    event.preventDefault()
    event.stopPropagation()
    this.$emit('activate-module', event, this.name)
    this.$bus.$emit('activate-module', this.name)
  }
}
